<template>
  <!--
    Displays the devices configuration in a specific way. (makes line-breaks and headlines)
  -->
  <div class="deviceDetailQtConfiguration">
    <pre>
      <div
        v-for="(field, fieldIndex) in myFields"
        :key="`deviceDetailQtConfiguration-fieldDiv-${ fieldIndex }`"
        class="fieldset px-4 py-3"
>
        <span class="headline p-1">{{ field.headline }}</span>
        <span
          v-for="(line, lineIndex) in field.lines"
          :key="`deviceDetailQtConfiguration-lineSpan-${ lineIndex }`"
          class="line"
>{{ line.line }}<span>{{ line.comment }}</span><br></span>
      </div>
    </pre>
  </div>
</template>

<script>
export default {
  name: 'DeviceDetailQtConfiguration',
  props: {
    configuration: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      myFields: [
        {
          headline: null,
          lines: []
        }
      ]
    }
  },
  created () {
    this.parseConfiguration();
  },
  methods: {
    parseConfiguration () {
      if (!this.configuration) {
        return;
      }
      let splitConfiguration = this.configuration.split('\n');
      splitConfiguration.forEach(line => {
        let trimmedLine = line.trim();
        if (!trimmedLine) {
          return;
        }
        if (!trimmedLine.length) {
          return;
        }
        if (trimmedLine.length == 0) {
          return;
        }
        let firstChar = line.charAt(0);
        if (firstChar === '[') {
          if (!this.myFields[this.myFields.length - 1].headline) {
            this.myFields[this.myFields.length - 1].headline = line;
          } else {
            this.myFields.push({
              headline: line,
              lines: []
            });
          }
          return;
        }
        if (this.divideLineAtChar(';', line)) {
          return;
        }
        if (this.divideLineAtChar('#', line)) {
          return;
        }
        this.myFields[this.myFields.length - 1].lines.push({
          line: line,
          comment: null
        });
      });
    },
    divideLineAtChar (character, line) {
      if (line.includes(character)) {
        let charPosition = line.indexOf(character);
        let lineSubstring = line.substr(0, charPosition);
        let commentSubstring = line.substr(charPosition);
        this.myFields[this.myFields.length - 1].lines.push({
          line: lineSubstring,
          comment: commentSubstring
        });
        return true;
      }
      return false;
    }
  }
}
</script>

<style scoped>
pre {
  white-space: nowrap;
  overflow: visible;
}
.fieldset {
  border: 1px solid lightgray;
  position: relative;
  margin-bottom: 2rem;
}
.fieldset .headline {
  background: white;
  position: absolute;
  top: -1.25rem;
  left: 50%;
  transform: translate(-50%, 0);
  font-weight: bold;
  font-size: 1.25rem;
}
.fieldset .line span {
  color: gray;
}
</style>
